<template>
  <div id="ConsoleHeader" :style="isMobile ? 'height:46px;border-bottom:1px solid #CDD0D8;' : ''">
    <template v-if="!isMobile">
      <div></div>
      <div class="LogoutBlock" @click="logout">
        <span class="logoutText">登出</span>
      </div>
    </template>
    <template v-else>
      <div
        class="menuOpenBlock"
        @click="$store.commit('admin/setIsShowMobileMenu', !isShowMobileMenu)"
      >
        <img src="@/assets/base/menuOpen.png" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'ConsoleHeader',
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
      isShowMobileMenu() {
        return this.$store.state.admin.isShowMobileMenu;
      },
    },
    methods: {
      logout() {
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('admin/logout')
          .then((res) => {
            this.$MSG.success('登出成功');
            this.$router.replace({ name: 'ConsoleLogin' });
          })
          .catch(this.$autoCatch)
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #ConsoleHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .LogoutBlock {
      width: fit-content;
      line-height: 40px;
      font-size: 12px;
      padding-right: 15px;
      .logoutText {
        cursor: pointer;
      }
    }
    .menuOpenBlock {
      display: flex;
      align-items: center;
      margin-left: 16px;
    }
  }
</style>
