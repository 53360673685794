<template>
  <div id="SideMenu">
    <div class="SideMenuContainer">
      <el-menu
        class="el-menu-vertical-demo"
        :default-active="menuDefaultValue"
        :collapse="isCollapse"
        style="height: 100%"
      >
        <template v-for="(item, index) in listComputed">
          <template v-if="canInMenu(item)">
            <!-- 如果有children key -->
            <el-submenu v-if="item.children !== undefined" :key="index" :index="index.toString()">
              <template slot="title">
                <i :class="item.icon"></i>
                <span class="titleText">{{ item.label }}</span>
              </template>
              <el-menu-item
                v-for="(item2, index2) in item.children"
                :index="`${index}-${index2}`"
                :key="`${index}-${index2}`"
                @click="clickMenuItem(item2)"
              >
                <div class="listChildren">
                  <span class="titleText"> {{ item2.label }}</span>
                </div>
              </el-menu-item>
            </el-submenu>
            <!-- 如果沒有children key -->
            <el-menu-item
              v-else
              :key="index"
              :index="index.toString()"
              @click="clickMenuItem(item)"
            >
              <div class="menuItemRow">
                <i :class="item.icon"></i>
                <span class="titleText" slot="title">{{ item.label }}</span>
              </div>
            </el-menu-item>
          </template>
        </template>
        <el-menu-item v-if="isMobile" @click="logout">
          <i class="el-icon-s-promotion"></i>
          <span class="titleText" slot="title">登出</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
  import { getLayoutList } from '@/utils/route';
  export default {
    data() {
      return {
        isCollapse: false,
        user: 0,
        menuDefaultValue: null,
        TotalMenuList: [],
      };
    },
    created() {
      const layoutChildren = getLayoutList().children;

      const Recursive = (it) => {
        let newIt = Object.assign({}, it);
        if (newIt?.children) {
          const arr = [];
          newIt.children.forEach((element) => {
            let newEle = Object.assign({}, element);
            if (newEle?.children) {
              newEle.children = Recursive(newEle);
            }
            newEle = { ...newEle.meta, name: newEle.name, children: newEle?.children };
            arr.push(newEle);
          });
          return arr;
        } else {
          newIt = { ...newIt.meta, name: newIt.name, children: newIt?.children };
          return newIt;
        }
      };
      const menuList = layoutChildren.map((route) => {
        let newRoute = Object.assign({}, route);
        if (newRoute?.children) {
          newRoute.children = Recursive(newRoute);
          newRoute = { ...newRoute.meta, name: newRoute.name, children: newRoute.children };
        } else {
          newRoute = { ...newRoute.meta, name: newRoute.name, children: undefined };
        }
        return newRoute;
      });

      this.TotalMenuList = menuList;
      this.updateMenuDefaultValue();
    },
    watch: {
      $route: function (to) {
        this.updateMenuDefaultValue();
      },
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
      listComputed() {
        if (this.$store.state.admin.userType === 'merchant') {
          return this.TotalMenuList.filter((it) => it.name !== 'ConsoleUserManagement');
        } else {
          return this.TotalMenuList;
        }
      },
    },
    methods: {
      logout() {
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('admin/logout')
          .then((res) => {
            this.$MSG.success('登出成功');
            this.$router.replace({ name: 'ConsoleLogin' });
          })
          .catch(this.$autoCatch)
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      updateMenuDefaultValue() {
        this.menuDefaultValue = this.initMenuDefaultValue();
      },
      canInMenu(it) {
        return !it?.NotInMenu;
      },
      isMenuOpen() {
        this.isCollapse = !this.isCollapse;
      },
      clickMenuItem(item) {
        this.$store.commit('admin/setIsShowMobileMenu', false);
        if (item?.type === 'backLand') {
          this.$router.push({ name: 'Landing_HomePage' });
        } else if (item?.type === 'logout') {
          this.$store.dispatch('logout');
          this.$router.push({ name: 'Login' });
        } else {
          this.$router.push({ name: item.name });
        }
      },
      initMenuDefaultValue() {
        let i = 0;
        let j = -1;
        let isFind = false;
        for (i = 0; i < this.listComputed.length; i++) {
          j = -1;
          const it = this.listComputed[i];
          if (it?.children) {
            for (j = 0; j < it.children.length; j++) {
              const it2 = it?.children[j];
              if (it2?.children) {
                const someRes = it2.children.some((it3) => {
                  if (it3.name === this.$route.name) {
                    isFind = true;
                    return true;
                  }
                });
                if (someRes) {
                  break;
                }
              }
              if (this.$route.name === it2.name) {
                isFind = true;
                break;
              }
            }
          } else if (this.$route.name === it.name) {
            break;
          }
          if (isFind) {
            break;
          }
        }
        if (j === -1) {
          return `${i}`;
        } else {
          return `${i}-${j}`;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #SideMenu {
    height: 100%;
    .SideMenuContainer {
      height: 100%;
      .menuItemRow {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
