<template>
  <div id="Layout">
    <!-- 手機面板 -->
    <template v-if="isMobile">
      <div class="LayoutLeftBlock" v-if="isShowMobileMenu" :class="LayoutLeftBlockClass">
        <SideMenu></SideMenu>
      </div>
      <div
        class="RightDisableBlock"
        @click="$store.commit('admin/setIsShowMobileMenu', false)"
        v-if="isShowMobileMenu"
      >
      </div>
    </template>
    <!-- PC面板 -->
    <template v-else>
      <div class="LayoutLeftBlock" :class="LayoutLeftBlockClass">
        <SideMenu></SideMenu>
      </div>
    </template>

    <div class="LayoutRightBlock" :class="LayoutRightBlockClass">
      <ConsoleHeader></ConsoleHeader>
      <div class="ConsoleLayoutRouteView">
        <div class="routeViewBorder"></div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import ConsoleHeader from '@/components/Console/ConsoleHeader.vue';
  import SideMenu from '@/components/Console/SideMenu.vue';
  export default {
    components: {
      ConsoleHeader,
      SideMenu,
    },
    name: 'Layout',
    data() {
      return {};
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
      isShowMobileMenu() {
        return this.$store.state.admin.isShowMobileMenu;
      },
      LayoutLeftBlockClass() {
        if (this.isMobile) {
          return 'LayoutLeftBlockMobile';
        } else {
          return '';
        }
      },
      LayoutRightBlockClass() {
        if (this.isMobile) {
          return 'LayoutRightBlockMobile';
        } else {
          return '';
        }
      },
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #Layout {
    height: 100%;
    display: flex;
    .LayoutLeftBlock {
      height: 100%;
      width: 200px;

      .el-menu {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .LayoutLeftBlockMobile {
      animation: fade 0.15s linear;
      position: absolute;
      top: 46px;
      z-index: 100;
      height: calc(100% - 46px);
    }
    .RightDisableBlock {
      background-color: rgba(0, 0, 0, 0.379);
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 46px;
      z-index: 99;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .LayoutRightBlock {
      overflow-x: auto;
      width: calc(100% - 200px);
      .ConsoleLayoutRouteView {
        padding: 20px 20px 0 20px;
        width: 100%;
        height: calc(100% - 40px);
      }
    }
    .LayoutRightBlockMobile {
      width: 100%;
      .ConsoleLayoutRouteView {
        height: calc(100% - 46px);
      }
    }
  }
</style>
